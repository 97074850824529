<template>
  <HeaderComponent/>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div class="form-check-inline">
      <form>
        <div class="form-group">
          <label><b>Ссылка на видео до регистрации</b></label>
          <input class="form-control" placeholder="Вставьте на видео" type="text" v-model="link_to_hello_video"/>
        </div>

        <div class="form-group">
          <label><b>Ссылка на приветственное видео</b></label>
          <input class="form-control" placeholder="Вставьте на видео" type="text" v-model="link_to_video"/>
        </div>

        <div class="form-group">
          <label><b>Ширина приветственного видео</b></label>
          <input class="form-control" placeholder="Ширина видео" type="text" v-model="width_of_video"/>
        </div>

        <div class="form-group">
          <label><b>Высота приветственного видео</b></label>
          <input class="form-control" placeholder="Высота видео" type="text" v-model="height_of_video"/>
        </div>

        <div class="form-group">
          <label><b>Сообщение при отправке макета на оценку</b></label>
          <textarea class="form-control" v-model="hello_message_layout">

          </textarea>
        </div>

        <hr>

        <button class="btn btn-success" @click="updateSettings" style="margin-top: 10px; background-color: #0353b2">
          Обновить настройки
        </button>
      </form>

      <a class="btn btn-success" href="/admin/translates" style="margin-top: 10px; background-color: #0353b2">
        Переводы
      </a>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import axios from "axios";

export default {
  name: "ConstructorSettings",
  data() {
    return {
      link_to_hello_video: '',

      link_to_video: '',
      width_of_video: '',
      height_of_video: '',
      hello_message_layout: ''
    };
  },
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},
  methods: {
    updateSettings() {
      axios.post("/api/admin/update_settings", {
            "token": String(localStorage.getItem("access_token")),
            "link_to_video": String(this.link_to_video),
            "link_to_hello_video": String(this.link_to_hello_video),
            "width_of_video": this.width_of_video,
            "height_of_video": this.height_of_video,
            "hello_message_layout": this.hello_message_layout,
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      )
    },
    loadSettings() {
      axios.get("/api/admin/settings", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.link_to_hello_video = response.data["link_to_hello_video"];
        this.link_to_video = response.data["link_to_video"];
        this.width_of_video = response.data["width_of_video"];
        this.height_of_video = response.data["height_of_video"];
        this.hello_message_layout = response.data["hello_message_layout"];
      })
    }
  },
  mounted() {
    this.loadSettings()
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 85vh;
}
</style>
