<template>
  <div
      style="width: 20vw; height: 100vh; background-color: #002154; white-space: nowrap; display: inline-block; float: left">
  </div>
  <div class="d-flex align-items-center justify-content-center"
       style="width: 30vw; height: 100vh; padding-left: 0; float: left">
    <span class="">
      <h1 class="font-semibold text-4xl mb-3">Админ-панель</h1>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="login"><span style="color: #002154; font-size: 14px"><b>ПОЧТА</b></span></label>
        <input
            id="login"
            style="width: 100%;"
            type="email"
            placeholder="Введите почту"
            name="login"
            v-model="login"
            class="form-control"
        />
        <p style="color: red">{{ email_message }}</p>
      </div>

      <div class="form-group" style="width: 18vw">
        <label class="form-label" for="psw"><span style="color: #002154; font-size: 14px;"><b>ПАРОЛЬ</b></span></label>
        <input id="psw"
               style="width: 100%;"
               type="password"
               placeholder="Введите пароль"
               class="form-control"
               name="psw"
               v-model="password">
        <p style="color: red">{{ password_message }}</p>
      </div>

      <button class="btn btn-primary mt-2" id="submit_button" @click="onSubmit">Войти</button>
    </span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminLogin",

  data() {
    return {
      login: '',
      password: '',
      email_message: '',
      password_message: ''
    }
  },
  methods: {
    onSubmit() {
      if (!this.login) {
        this.email_message = "Введите почту"
        return
      } else this.email_message = ""
      if (!this.password) {
        this.password_message = "Введите пароль"
        return
      } else this.password_message = ""

      axios.post("/api/admin/login", {
            "email": String(this.login),
            "password": String(this.password),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => {
        if (response.data["access_token"] && response.data["refresh_token"]) {
          localStorage.setItem("access_token", response.data["access_token"])
          localStorage.setItem("refresh_token", response.data["refresh_token"])
          if (response.data["role"] === "admin") {
            window.location.href = "/admin/customers_chat"
          } else if (response.data["role"] === "manager") {
            window.location.href = "/admin/manager_customers_page"
          }
        }
      }).catch((reason) => alert(reason.response.data.detail))
    }
  }
}
</script>

<style>
body {
  font-family: Ubuntu;
}

#submit_button {
  width: 100%;
  background-color: #004376;
}

#submit_button:hover {
  background-color: #002154;
}
</style>