<template>
  <HeaderComponent/>
  <a href="#" id="back_button" style="position: absolute; margin-left: 4vw; font-size: 20px; color: black" @click="this.$router.go(-1)">
    Назад
  </a>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Чаты производителя
  </h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div>
      <div class="form-group">
        <label><b>Наименование</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['name']"/>
      </div>

      <div class="form-group">
        <label><b>Контактное лицо</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['contact_person']"/>
      </div>

      <div class="form-group">
        <label><b>Телефон</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['phone']"/>
      </div>

      <div class="form-group">
        <label><b>Почта</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['email']"/>
      </div>

      <div class="form-group">
        <label><b>ИНН</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['TIN']"/>
      </div>

      <div class="form-group" style="width: 60vw">
        <label><b>Описание</b></label>
        <textarea readonly class="form-control" type="text" v-model="this.user['about']"
                  style="min-height: 20vh"></textarea>
      </div>

      <button v-if="this.user['status'] === 1" class="btn btn-danger mt-2" type="button"
              data-bs-toggle="modal" data-bs-target="#blockModal">
        Заблокировать пользователя
      </button>
      <button v-if="this.user['status'] === -1" class="btn btn-success mt-2" type="button"
              data-bs-toggle="modal" data-bs-target="#blockModal">
        Разблокировать пользователя
      </button>

      <div id="chats">
        <table class="table" style="width: 100%">
          <thead>
          <th>ID</th>
          <th>Название</th>
          <th>Дата последнего сообщения</th>
          <th>Статус</th>
          <th></th>
          <th></th>
          </thead>
          <tbody>
          <tr v-for="index in Object.keys(this.orders)" v-bind:key="index" style="width: 100%;"
              :class="check_if_unread(this.orders[index]['read_by_admin'])">
            <td> {{ this.orders[index]["_id"] }}</td>
            <td> {{ this.orders[index]["name"] }}</td>
            <td> {{ new Date(this.orders[index]["last_message_at"]).toLocaleString().replace(',',' ') }}</td>
            <td> {{ statuses[this.orders[index]["status"]] }}</td>
            <td>
              <a :href="'/admin/customer_chat/' + this.orders[index]['_id']"
                 style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
                Открыть чат
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <FooterComponent/>

  <div class="modal fade" id="blockModal" tabindex="-1" style="height: 11rem" aria-labelledby="BlockLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 11rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 11rem; padding: 0; margin: 0">
        <div class="modal-header">
          <h5  v-if="user['status'] === 1" class="modal-title text-danger" id="BlockLabel">Вы уверены, что хотите заблокировать пользователя?</h5>
          <h5  v-if="user['status'] === -1" class="modal-title text-success" id="BlockLabel">Вы уверены, что хотите разблокировать пользователя?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="text-align: center">
          <button v-if="user['status'] === 1" class="btn btn-danger" @click="block()" style="margin-right: 3rem">
            Заблокировать
          </button>
          <button v-if="user['status'] === -1" class="btn btn-success" @click="unblock()" style="margin-right: 3rem">
            Разблокировать
          </button>
          <button class="btn btn-primary" data-bs-dismiss="modal">
            Отмена
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'OneCustomerChat',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      user: {},
      orders: {},
      message: '',

      statuses: {
        0: "На оценке",
        1: "Отказ",
        2: "Принято в работу",
        3: "В пошиве",
        4: "Завершено",
      }
    }
  },

  methods: {
    get_user() {
      axios.get("/api/admin/companies/" + this.$route.params["id"], {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.user = response.data;
        axios.post("/api/admin/get_company_orders/", {
              "token": localStorage.getItem("access_token"),
              "email": this.user["email"],
            },
            {
              headers: {
                "Content-Type": "application/json",
              }
            }).then((orders_response) => {
          this.orders = orders_response.data;
        })
      })
    },

    block() {
      axios.post("/api/admin/block_company", {
            "token": localStorage.getItem("access_token"),
            "user_id": Number(this.$route.params["id"]),
          }, {
            headers: {
              "Content-Type": "application/json",
            }
          }
      ).then(() => {
        this.$router.go(-1)
      })
    },

    unblock() {
      axios.post("/api/admin/unblock_company", {
            "token": localStorage.getItem("access_token"),
            "user_id": Number(this.$route.params["id"]),
          }, {
            headers: {
              "Content-Type": "application/json",
            }
          }
      ).then(() => {
        this.$router.go(-1)
      })
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "unread_dialog" : "";
    }
  },

  mounted() {
    this.get_user();
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

#chats {
  width: 100%
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}

.unread_dialog {
  --bs-table-bg-state: #FFDB93;
}

.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}
</style>
