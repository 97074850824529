<template>
  <div class="custom_container">
    <div style="margin-bottom: 2vh">
      <img src="lensiz_logo_vertical.png" style=" margin: 0; padding: 0; width: 10vw; height: 10vw" id="logo"/>
    </div>
    <div>
      <h3 style="margin-bottom: 2vh">
        Ваша заявка принята, ожидайте!
        <br>
        Менеджер скоро вам позвонит!
      </h3>
    </div>
    <div>
      <iframe :width=width_of_video :height=height_of_video :src=link_to_video
              title="RuTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "WaitPage",

  data() {
    return {
      link_to_video: '',
      width_of_video: 0,
      height_of_video: '',
    }
  },

  methods: {
    loadSettings() {
      axios.get("/api/admin/settings", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.link_to_video = response.data["link_to_video"];
        this.width_of_video = response.data["width_of_video"];
        this.height_of_video = response.data["height_of_video"];
      })
    }
  },

  mounted() {
    this.loadSettings()
  }
}
</script>

<style scoped>
.custom_container {
  font-family: Ubuntu;
  margin-top: 5vh;
  width: 100vw;
  height: 93vh;
  vertical-align: middle;
  text-align: center;
}
</style>