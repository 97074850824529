<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div class="form-check-inline" style="width: 100%;">
      Часто задаваемые вопросы
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/customer/HeaderComponent";
import MenuComponent from "@/customer/MenuComponent";
import FooterComponent from "@/customer/FooterComponent";

export default {
  name: "FAQPage",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
    }
  },

  methods: {}
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}
</style>