<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Чаты с заказчиками</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div style="width: 100%">
      <div style="width: 75vw; vertical-align: center; text-align: center">
        <div class="form-group" style="display: inline-block; margin-right: 20px">
          <input class="form-control" placeholder="Почта заказчика" type="text" v-model="email_of_customer"/>
        </div>

        <div class="form-group" style="display: inline-block; margin-right: 20px">
          <input class="form-control" placeholder="Наименование компании" type="text" v-model="name_of_customer"/>
        </div>

        <div style="display: inline-block; margin-right: 20px">
          <input v-model="unread_messages" @change="update_orders(current_page)" type="checkbox"
                 class="form-check" style="display: inline-block; vertical-align: middle; margin: 5px 5px 5px 0;">
          <div style="display: inline-block; vertical-align: middle">
            Непрочитанные сообщения
          </div>
        </div>

        <button class="btn btn-danger"
                style="height: 100%; width: 100px; background-color: green; border: 0; margin-bottom: 3px"
                @click="update_orders(this.current_page)">
          Поиск
        </button>
      </div>
      <br>

      <div class="form_radio_group">
        <div class="form_radio_group-item">
          <input id="radio-0" type="radio" value="-1" @click="orders_status=-1; update_page()"
                 :checked="orders_status===-1">
          <label for="radio-0">Все</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-1" type="radio" value="0" @click="orders_status=0; update_page()"
                 :checked="orders_status===0">
          <label for="radio-1">На оценке</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-2" type="radio" value="1" @click="orders_status=1; update_page()"
                 :checked="orders_status===1">
          <label for="radio-2">Отказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-3" type="radio" value="2" @click="orders_status=2; update_page()"
                 :checked="orders_status===2">
          <label for="radio-3">Принятые заказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-4" type="radio" value="3" @click="orders_status=3; update_page()"
                 :checked="orders_status===3">
          <label for="radio-4">На пошиве</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-5" type="radio" value="4" @click="orders_status=4; update_page()"
                 :checked="orders_status===4">
          <label for="radio-5">Завершенные заказы</label>
        </div>
      </div>
      <table class="table" style="width: 100%">
        <thead>
        <th>ID</th>
        <th>Название</th>
        <th>Почта</th>
        <th>Компания</th>
        <th>Дата последнего сообщения</th>
        <th v-if="orders_status===-1">Статус</th>
        <th></th>
        <th></th>
        <th></th>
        </thead>
        <tbody>
        <tr v-for="index in Object.keys(this.orders)" v-bind:key="index" style="width: 100%;"
            :class="check_if_unread(this.orders[index]['read_by_admin'])">
          <td> {{ this.orders[index]["_id"] }}</td>
          <td> {{ this.orders[index]["name"] }}</td>
          <td> {{ this.orders[index]["email"] }}</td>
          <td> {{ this.orders[index]["customer_name"] }}</td>
          <td> {{ new Date(this.orders[index]["last_message_at"]).toLocaleString().replace(',', ' ') }}</td>
          <td v-if="orders_status===-1">{{ choices_text[Number(this.orders[index]["status"])] }}</td>
          <td>
            <a :href="'/admin/customer_chat/' + this.orders[index]['_id']"
               style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
              Открыть чат
            </a>
          </td>
          <td>
            <a href="#" @click="clicked_to_remove = this.orders[index]['_id']"
               data-bs-toggle="modal" data-bs-target="#deleteModal"
               style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #cb292f; border: 0">
              Удалить
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <br>

      <div v-for="page in Math.ceil(orders_count/current_limit)" v-bind:key="page"
           style="display: inline-block;">
        <a @click="update_orders(page)" :class="{ page_button: true, active: page===current_page}">{{ page }} </a>
      </div>
    </div>
  </div>
  <FooterComponent/>
  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 10rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 10rem; padding: 0; margin: 0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <h5 class="modal-title" id="deleteModalLabel" style="text-align: center">Вы действительно хотите удалить
          заказ?</h5>
        <div style="text-align: center; padding-top: 20px">
          <button class="btn btn-danger" @click="remove_project()" style="font-size: 17px">Да</button>
          <button class="btn btn-primary" style="margin-left: 20px; font-size: 17px" data-bs-dismiss="modal">Нет
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'CustomersPage',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      orders: [],

      orders_count: 0,
      current_page: 1,
      current_limit: 60,

      name_of_customer: '',
      email_of_customer: '',
      unread_messages: 0,

      orders_status: -1,

      choices_text: {
        0: "На оценке",
        1: "Отказано",
        2: "Принято в работу",
        3: "На пошиве",
        4: "Завершен",
      },

      clicked_to_remove: -1,
    }
  },

  methods: {
    update_page() {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("orders_status", this.orders_status);
      window.location.search = searchParams.toString();
    },

    get_orders: function () {
      let find_options = {
        "email": {$regex: `${this.email_of_customer}`, $options: 'i'},
        "customer_name": {$regex: `${this.name_of_customer}`, $options: 'i'},
      }

      if (new URLSearchParams(window.location.search).get("orders_status")) {
        this.orders_status = Number(new URLSearchParams(window.location.search).get("orders_status"));
      }

      if (this.orders_status !== -1) {
        find_options["status"] = this.orders_status
      }

      if (this.unread_messages === true) {
        find_options["read_by_admin"] = false;
      }
      axios.post("/api/admin/customers_chat", {
            "token": String(localStorage.getItem("access_token")),
            "page": this.current_page,
            "limit": this.current_limit,
            "find_options": find_options,
          }, {
            headers: {}
          }
      ).then((response) => {
        this.orders = response.data["orders"];
        this.orders_count = response.data["orders_count"];
      })
    },

    update_orders(page) {
      this.current_page = page;
      this.get_orders()
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "unread_dialog" : "";
    },

    remove_project() {
      axios.post("/api/admin/accept_remove", {
        "token": String(localStorage.getItem("access_token")),
        "order_id": Number(this.clicked_to_remove),
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(() => {
        window.location.reload();
      })
    }
  },

  mounted() {
    this.get_orders()
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}

.unread_dialog {
  --bs-table-bg-state: #FFDB93;
}

.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}


.form_radio_group {
  display: inline-block;
  overflow: hidden;
}

.form_radio_group-item {
  display: inline-block;
  float: left;
}

.form_radio_group input[type=radio] {
  display: none;
}

.form_radio_group label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  user-select: none;
}

.form_radio_group .form_radio_group-item:first-child label {
  border-radius: 6px 0 0 6px;
}

.form_radio_group .form_radio_group-item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
  background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
  color: #666;
}

/* Disabled */
.form_radio_group input[type=radio]:disabled + label {
  background: #efefef;
  color: #666;
}

</style>
