<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Редактирование оферты</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div class="form-check-inline" v-if="access_to_super_admin === false">
      <form>
        <div class="form-group">
          <label><b>Введите супер-пароль</b></label>
          <input class="form-control" placeholder="Пароль" type="text" v-model="password"/>
        </div>

        <button class="btn btn-success" @click.prevent="loginPanel" style="margin-top: 10px; background-color: #0353b2">
          Войти
        </button>
      </form>
    </div>

    <div class="form-check-inline" v-if="access_to_super_admin === true">
      <div v-if="!(change_company_offer || change_customer_offer)">
        <a href="#" @click="change_customer_offer=1" style="color: black;">Изменить оферту для заказчиков</a>
        <br>
        <a href="#" @click="change_company_offer=1" style="color: black;">Изменить оферту для поставщиков</a>
      </div>

      <form v-if="change_company_offer || change_customer_offer">
        <h2 class="display-10" v-if="change_company_offer">
          Изменение оферты для поставщиков
        </h2>
        <h2 class="display-10" v-if="change_customer_offer">
          Изменение оферты для заказчиков
        </h2>
        <textarea v-if="change_company_offer" v-model="company_offer" class="form-control"
                  style="height: 16rem; width: 60vw; margin: 10px;"></textarea>

        <textarea v-if="change_customer_offer" v-model="customer_offer" class="form-control"
                  style="height: 16rem; width: 60vw; margin: 10px;"></textarea>

        <button class="btn btn-success" @click="updateData" style="margin-top: 10px; background-color: #0353b2">
          Подтвердить
        </button>
      </form>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default {
  name: "CreateManager",
  components: {AdminMenuComponent, HeaderComponent, FooterComponent},

  data() {
    return {
      password: '',
      new_password: '',
      access_to_super_admin: false,

      change_company_offer: 0,
      change_customer_offer: 0,

      company_offer: '',
      customer_offer: '',
    }
  },

  methods: {
    loginPanel() {
      axios.post("/api/admin/authorize_super_admin", {
            "token": String(this.password),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => {
        this.access_to_super_admin = response.data
      }).catch((reason) => console.log(reason))
    },
    updateData() {
      if (this.change_company_offer) {
        axios.post("/api/admin/change_company_offer", {
              "token": String(localStorage.getItem("access_token")),
              "offer": this.company_offer
            }, {
              headers: {
                'Accept':
                    'application/json',
                'Content-Type':
                    'application/json'
              }
            }
        ).catch((reason) => console.log(reason))
      } else {
        axios.post("/api/admin/change_customer_offer", {
              "token": String(localStorage.getItem("access_token")),
              "offer": String(this.customer_offer)
            }, {
              headers: {
                'Accept':
                    'application/json',
                'Content-Type':
                    'application/json'
              }
            }
        ).catch((reason) => console.log(reason))
      }
    },
  },

  mounted() {
    axios.get("/api/admin/company_offer", {
          headers: {
            'Accept':
                'application/json',
            'Content-Type':
                'application/json'
          }
        }
    ).then((response) => this.company_offer = response.data).catch((reason) => console.log(reason))

    axios.get("/api/admin/customer_offer", {
          headers: {
            'Accept':
                'application/json',
            'Content-Type':
                'application/json'
          }
        }
    ).then((response) => this.customer_offer = response.data).catch((reason) => console.log(reason))
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 85vh;
}
</style>
