<template>
  <HeaderComponent/>
  <a href="#" id="back_button" style="position: absolute; margin-left: 4vw; font-size: 20px; color: black" @click="this.$router.go(-1)">
    Назад
  </a>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Список заказчиков у менеджера <br> {{ manager["name"] }}</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div style="display: table">
      <div style="vertical-align: middle; width: 75vw; text-align: center">
        <div class="form-group" style="display: inline-block">
          <input class="form-control" placeholder="Наименование" type="text" v-model="name_of_customer"/>
        </div>
        <div class="form-group" style="display: inline-block; margin-left: 20px">
          <input class="form-control" placeholder="ИНН" type="text" v-model="TIN_of_customer"/>
        </div>
        <div class="form-group" style="display: inline-block; margin-left: 20px">
          <input class="form-control" placeholder="Описание" type="text" v-model="about_customer"/>
        </div>
        <button class="btn btn-danger"
                style="height: 100%; width: 100px; background-color: green; border: 0; margin-bottom: 3px; margin-left: 20px"
                @click="update_users(this.current_page)">
          Поиск
        </button>
      </div>

      <table class="table" style="width: 100%">
        <thead>
        <th scope="col">ID</th>
        <th scope="col">Компания</th>
        <th scope="col">ИНН</th>
        <th scope="col">Описание</th>
        <th scope="col"></th>
        </thead>
        <tbody>
        <tr v-for="user in Object.keys(users)" v-bind:key="user" style="width: 100%;">
          <td> {{ users[user]["_id"] }}</td>
          <td> {{ users[user]["name"] }}</td>
          <td> {{ users[user]["TIN"] }}</td>
          <td> {{ users[user]["about"] }}</td>
          <td>
            <a :href="'/admin/one_customer_chat/' + users[user]['_id']" style="color: black">
              Открыть
            </a>
          </td>
          <td>
            <a :href="'/admin/customers/' + users[user]['_id']" style="color: black">
              Редактировать
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <br>
      <div v-for="page in Math.ceil(users_count/current_limit)" v-bind:key="page" style="display: inline-block;">
        <a @click="update_users(page)" :class="{ page_button: true, active: page===current_page}">{{ page }} </a>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'ManagerCustomers',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      users: [],
      manager: {},
      users_count: 0,
      current_page: 1,
      current_limit: 60,
      name_of_customer: '',
      TIN_of_customer: '',
      about_customer: '',
    }
  },

  methods: {
    get_users: function () {
      axios.post("/api/admin/customers", {
            "page": this.current_page,
            "limit": this.current_limit,
            "find_options": {
              "manager": Number(this.$route.params["id"]),
              "name": {$regex: `${this.name_of_customer}`, $options: 'i'},
              "TIN": {$regex: `${this.TIN_of_customer}`, $options: 'i'},
              "about": {$regex: `${this.about_customer}`, $options: 'i'}
            },
          }, {
            headers: {}
          }
      ).then((response) => {
        this.users = response.data["users"];
        this.users_count = response.data["users_count"];
      })
    },

    update_users(page) {
      this.current_page = page;
      this.get_users()
    },

    get_manager() {
      axios.post("/api/admin/get_manager", {
        "token": localStorage.getItem("access_token"),
        "user_id": this.$route.params["id"]
      }, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.manager = response.data;
      })
    }
  },

  mounted() {
    this.get_users();
    this.get_manager();
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}
</style>