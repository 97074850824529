<template>
  <div
      style="width: 20vw; height: 100vh; background-color: #002154; white-space: nowrap; display: inline-block; float: left">
  </div>
  <div class="d-flex align-items-center justify-content-center"
       style="width: 30vw; height: 100vh; padding-left: 0; float: left">
    <span class="">
      <h1 class="font-semibold text-4xl mb-3">Восстановление пароля</h1>

      <div class="form-group">
        <label class="form-label" for="email"><span style="color: #002154; font-size: 14px"><b>ПОЧТА</b></span></label>
        <input
            id="email"
            style="width: 100%;"
            type="email"
            placeholder="Введите почту"
            name="email"
            v-model="email"
            class="form-control"
        />
        <p style="color: red">{{ email_message }}</p>
      </div>

      <button class="btn btn-primary mt-2" id="submit_button" @click="onSubmit">Восстановить пароль</button>
    </span>
  </div>
  <div class="d-flex align-items-center justify-content-center" style="margin-left: 50vw; width: 45vw; height: 100vh;">
    <iframe :src="link_to_hello_video" title="YouTube video player" style="width: 40vw; height: 45vh"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPasswordPage",
  data() {
    return {
      link_to_hello_video: '',

      email: '',
      email_message: '',
    }
  },
  methods: {
    onSubmit() {
      if (!this.email) {
        this.email_message = "Введите почту"
      } else this.email_message = ""

      axios.post("/api/send_recovery_email", {
        "email": this.email
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(() => {
        alert("Ссылка на восстановление пароля отправлена на почту!")
      })
    },

    loadSettings() {
      axios.get("/api/admin/settings", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.link_to_hello_video = response.data["link_to_hello_video"];
      })
    }
  },

  mounted() {
    this.loadSettings();
    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript)
  }
}
</script>

<style>
body {
  font-family: Ubuntu;
}

#submit_button {
  width: 100%;
  background-color: #004376;
}

#submit_button:hover {
  background-color: #002154;
}
</style>
