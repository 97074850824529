<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Список моделей</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div>
      <button style="border-radius: 10px; margin-right: 10px">
        <a href="/admin/load_model" style="color: black; font-size: 20px">Загрузить модель</a>
      </button>

      <button style="border-radius: 10px">
        <a @click.prevent="check_models" style="color: black; font-size: 20px">Проверить модели</a>
      </button>

      <div style="vertical-align: middle; width: 75vw; text-align: center">
        <div style="text-align: left; margin-bottom: 10px">
          {{ this.check_message }}
          <div v-if="errors !== []">
            <div v-for="error in errors" v-bind:key="error">
              <p>
                {{ error }}
              </p>
            </div>
          </div>
          <div v-else>
            Все модели в порядке!
          </div>
        </div>
        <div class="form-group" style="display: inline-block">
          <input class="form-control" placeholder="Путь" type="text" v-model="path_of_model"/>
        </div>
        <div class="form-group" style="display: inline-block; margin-left: 20px">
          <input class="form-control" placeholder="Тип" type="text" v-model="type_of_model"/>
        </div>
      </div>

      <table>
        <tr style="text-align: center">
          <th>id</th>
          <th>Название</th>
          <th>Название в ФС</th>
          <th>Путь</th>
          <th>Тип</th>
          <th>Статус</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="index in Object.keys(models.filter((model) =>
        (model['path_to_model'].toLowerCase().indexOf(path_of_model.toLowerCase()) >= 0) &&
        (model['type'].toLowerCase().indexOf(type_of_model.toLowerCase()) >= 0)))"
            v-bind:key="index" style="width: 100%;">
          <td> {{ models[index]["_id"] }}</td>
          <td> {{ models[index]["name"] }}</td>
          <td> {{ models[index]["model"] }}</td>
          <td> {{ models[index]["path_to_model"] }}</td>
          <td> {{ models[index]["type"] }}</td>
          <td> {{ models[index]["status"] }}</td>
          <td>
            <a :href="'/admin/models/' + models[index]['_id']" style="color: black">
              Редактировать
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'ModelList',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      models: [],
      errors: [],

      type_of_model: '',
      path_of_model: '',

      check_message: ''
    }
  },

  methods: {
    get_models() {
      axios.get("/api/admin/models", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.models = response.data;
      })
    },

    check_models() {
      this.check_message = ''
      this.errors = []
      this.check_message += "Всего моделей: " + this.models.length + '\n'
      let paths = [];
      for (let model in this.models) {
        paths.push(this.models[model]['path_to_model'].toLowerCase() + '/' + this.models[model]["model"])
      }

      for (let path_num in paths) {
        if (paths[path_num].includes("/man/")) {
          if (!(paths.includes(paths[path_num].replace("/man/", "/woman/")))) {
            this.errors.push(paths[path_num])
          }
        }
        if (paths[path_num].includes("/woman/")) {
          if (!(paths.includes(paths[path_num].replace("/woman/", "/man/")))) {
            this.errors.push(paths[path_num])
          }
        }
      }

      axios.post("/api/admin/check_models_existing", {
        "token": localStorage.getItem("access_token")
      }, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        for (let model_path_num in response.data) {
          this.errors.push(`Не существует в ФС: ${response.data[model_path_num]}`)
        }
      })
    }
  },

  mounted() {
    this.get_models()
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 70vh;
}

td {
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid #c4c4c4;
  height: 5vh
}

table th {
  background: #333333;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}
</style>
