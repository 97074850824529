<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Список заказчиков</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div style="width: 100%">
      <div class="form_radio_group">
        <div class="form_radio_group-item">
          <input id="radio-0" type="radio" value="1" @click="users_status=1; update_page()"
                 :checked="users_status===1">
          <label for="radio-0">Активные пользователи</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-1" type="radio" value="-1" @click="users_status=-1; update_page()"
                 :checked="users_status===-1">
          <label for="radio-1">Забаненные пользователи</label>
        </div>
      </div>

      <div style="display: table">
        <div style="vertical-align: middle; width: 75vw; text-align: center">
          <div class="form-group" style="display: inline-block">
            <input class="form-control" placeholder="Наименование" type="text" v-model="name_of_customer"/>
          </div>
          <div class="form-group" style="display: inline-block; margin-left: 20px">
            <input class="form-control" placeholder="ИНН" type="text" v-model="TIN_of_customer"/>
          </div>
          <div class="form-group" style="display: inline-block; margin-left: 20px">
            <input class="form-control" placeholder="Описание" type="text" v-model="about_customer"/>
          </div>
          <button class="btn btn-danger"
                  style="height: 100%; width: 100px; background-color: green; border: 0; margin-bottom: 3px; margin-left: 20px"
                  @click="update_users(this.current_page)">
            Поиск
          </button>
        </div>

        <table class="table" style="width: 100%">
          <thead>
          <th scope="col">ID</th>
          <th scope="col">Компания</th>
          <th scope="col">ИНН</th>
          <th scope="col">Описание</th>
          <th scope="col"></th>
          </thead>
          <tbody>
          <tr v-for="user in Object.keys(users)" v-bind:key="user" style="width: 100%;">
            <td> {{ users[user]["_id"] }}</td>
            <td> {{ users[user]["name"] }}</td>
            <td> {{ users[user]["TIN"] }}</td>
            <td> {{ users[user]["about"] }}</td>
            <td>
              <a :href="'/admin/one_customer_chat/' + users[user]['_id']" style="color: black">
                Открыть
              </a>
            </td>
            <td>
              <a :href="'/admin/customers/' + users[user]['_id']" style="color: black">
                Редактировать
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <br>
        <div v-for="page in Math.ceil(users_count/current_limit)" v-bind:key="page" style="display: inline-block;">
          <a @click="update_users(page)" :class="{ page_button: true, active: page===current_page}">{{ page }} </a>
        </div>
      </div>
    </div>

  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'CustomersPage',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      users: [],
      users_count: 0,
      current_page: 1,
      current_limit: 60,
      name_of_customer: '',
      TIN_of_customer: '',
      about_customer: '',

      users_status: 1
    }
  },

  methods: {
    update_page() {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("users_status", this.users_status);
      window.location.search = searchParams.toString();
    },

    get_users: function () {
      if (new URLSearchParams(window.location.search).get("users_status")) {
        this.users_status = Number(new URLSearchParams(window.location.search).get("users_status"));
      }

      axios.post("/api/admin/customers", {
            "page": this.current_page,
            "limit": this.current_limit,
            "find_options": {
              "status": this.users_status,
              "name": {$regex: `${this.name_of_customer}`, $options: 'i'},
              "TIN": {$regex: `${this.TIN_of_customer}`, $options: 'i'},
              "about": {$regex: `${this.about_customer}`, $options: 'i'}
            },
          }, {
            headers: {}
          }
      ).then((response) => {
        this.users = response.data["users"];
        this.users_count = response.data["users_count"];
        console.log(response.data)
      })
    },

    update_users(page) {
      this.current_page = page;
      this.get_users()
    }
  },

  mounted() {
    this.get_users()
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}


.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}


.form_radio_group {
  display: inline-block;
  overflow: hidden;
}

.form_radio_group-item {
  display: inline-block;
  float: left;
}

.form_radio_group input[type=radio] {
  display: none;
}

.form_radio_group label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  user-select: none;
}

.form_radio_group .form_radio_group-item:first-child label {
  border-radius: 6px 0 0 6px;
}

.form_radio_group .form_radio_group-item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
  background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
  color: #666;
}
</style>
