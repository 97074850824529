<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Чаты заказчика
  </h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div>
      <div class="form-group">
        <label><b>Наименование</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['name']"/>
      </div>

      <div class="form-group">
        <label><b>Контактное лицо</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['contact_person']"/>
      </div>

      <div class="form-group">
        <label><b>Телефон</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['phone']"/>
      </div>

      <div class="form-group">
        <label><b>Почта</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['email']"/>
      </div>

      <div class="form-group">
        <label><b>ИНН</b></label>
        <input readonly class="form-control" type="text" v-model="this.user['TIN']"/>
      </div>

      <div class="form-group" style="width: 60vw">
        <label><b>Описание</b></label>
        <textarea class="form-control" type="text" v-model="this.user['about']"
                  style="min-height: 20vh"></textarea>
      </div>

      <button class="btn btn-primary mt-3" @click="update_description">
        Обновить описание
      </button>

      <div id="chats">
        <div class="form_radio_group">
          <div class="form_radio_group-item">
            <input id="radio-0" type="radio" value="-1" @click="orders_status=-1; update_page()"
                   :checked="orders_status===-1">
            <label for="radio-0">Все</label>
          </div>
          <div class="form_radio_group-item">
            <input id="radio-1" type="radio" value="0" @click="orders_status=0; update_page()"
                   :checked="orders_status===0">
            <label for="radio-1">На оценке</label>
          </div>
          <div class="form_radio_group-item">
            <input id="radio-2" type="radio" value="1" @click="orders_status=1; update_page()"
                   :checked="orders_status===1">
            <label for="radio-2">Отказы</label>
          </div>
          <div class="form_radio_group-item">
            <input id="radio-3" type="radio" value="2" @click="orders_status=2; update_page()"
                   :checked="orders_status===2">
            <label for="radio-3">Принятые заказы</label>
          </div>
          <div class="form_radio_group-item">
            <input id="radio-4" type="radio" value="3" @click="orders_status=3; update_page()"
                   :checked="orders_status===3">
            <label for="radio-4">На пошиве</label>
          </div>
          <div class="form_radio_group-item">
            <input id="radio-5" type="radio" value="4" @click="orders_status=4; update_page()"
                   :checked="orders_status===4">
            <label for="radio-5">Завершенные заказы</label>
          </div>
        </div>
        <table class="table" style="width: 100%">
          <thead>
          <th>ID</th>
          <th>Название</th>
          <th>Дата последнего сообщения</th>
          <th>Статус</th>
          <th></th>
          </thead>
          <tbody>
          <tr v-for="index in Object.keys(this.orders)" v-bind:key="index" style="width: 100%;"
              :class="check_if_unread(this.orders[index]['read_by_admin'])">
            <td> {{ this.orders[index]["_id"] }}</td>
            <td> {{ this.orders[index]["name"] }}</td>
            <td> {{ new Date(this.orders[index]["last_message_at"]).toLocaleString().replace(',',' ') }}</td>
            <td> {{ statuses[this.orders[index]["status"]] }}</td>
            <td>
              <a :href="'/admin/manager_customers_chat/' + this.orders[index]['_id']"
                 style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
                Открыть чат
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/ManagerPages/MenuComponent";
import HeaderComponent from "@/admin/ManagerPages/HeaderComponent";
import FooterComponent from "@/admin/ManagerPages/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'OneCustomerChat',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      user: {},
      orders: [],

      orders_count: 0,
      current_page: 1,
      current_limit: 60,

      name_of_customer: '',
      email_of_customer: '',
      unread_messages: 0,

      orders_status: -1,

      statuses: {
        0: "На оценке",
        1: "Отказ",
        2: "Принято в работу",
        3: "В пошиве",
        4: "Завершено",
      }
    }
  },

  methods: {
    get_user() {
      axios.post("/api/admin/manager_one_customer", {
        "token": localStorage.getItem("access_token"),
        "user_id": this.$route.params["id"],
      },{
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.user = response.data;
      })
    },
    update_page() {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("orders_status", this.orders_status);
      window.location.search = searchParams.toString();
    },

    get_orders: function () {
      let find_options = {
        "email": {$regex: `${this.email_of_customer}`, $options: 'i'},
        "customer_name": {$regex: `${this.name_of_customer}`, $options: 'i'},
      }

      if (new URLSearchParams(window.location.search).get("orders_status")) {
        this.orders_status = Number(new URLSearchParams(window.location.search).get("orders_status"));
      }

      if (this.orders_status !== -1) {
        find_options["status"] = this.orders_status
      }

      if (this.unread_messages === true) {
        find_options["read_by_admin"] = false;
      }
      axios.post("/api/admin/customers_chat", {
            "token": String(localStorage.getItem("access_token")),
            "page": 1,
            "limit": 500,
            "find_options": find_options,
          }, {
            headers: {}
          }
      ).then((response) => {
        this.orders = response.data["orders"];
        this.orders_count = response.data["orders_count"];
      })
    },

    update_orders(page) {
      this.current_page = page;
      this.get_orders()
    },

    update_description() {
      axios.post("/api/admin/customer_update_description", {
        "token": localStorage.getItem("access_token"),
        "user_id": this.$route.params["id"],
        "description": this.user["about"],
      },{
        headers: {
          "Content-Type": "application/json",
        }
      })
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "unread_dialog" : "";
    }
  },

  mounted() {
    this.get_user();
    this.get_orders();
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

#chats {
  margin-top: 20px;
  width: 100%
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}

.unread_dialog {
  --bs-table-bg-state: #FFDB93;
}

.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}


.form_radio_group {
  display: inline-block;
  overflow: hidden;
}

.form_radio_group-item {
  display: inline-block;
  float: left;
}

.form_radio_group input[type=radio] {
  display: none;
}

.form_radio_group label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  user-select: none;
}

.form_radio_group .form_radio_group-item:first-child label {
  border-radius: 6px 0 0 6px;
}

.form_radio_group .form_radio_group-item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
  background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
  color: #666;
}

/* Disabled */
.form_radio_group input[type=radio]:disabled + label {
  background: #efefef;
  color: #666;
}

</style>
