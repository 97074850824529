<template>
  <div id="menu">
    <ul class="menu_container list-group list-group-flush">
      <li class="menu_button list-group-item" style="border-radius: 10px">
        <a href="/admin/manager_constructor" class="menu_text">Конструктор</a></li>
      <li class="menu_button list-group-item" style="border-radius: 10px">
        <a href="/admin/manager_projects" class="menu_text">Мои проекты</a></li>
      <li class="menu_button list-group-item">
        <a href="/admin/manager_customers" class="menu_text">Покупатели</a></li>
      <li class="menu_button list-group-item">
        <a href="/admin/manager_customers_page" class="menu_text">Чаты с покупателями</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ManagerMenuComponent"
}
</script>

<style scoped>
#menu {
  font-family: Ubuntu;
  display: table-cell;
  float: left;
  width: 20%;
  border-radius: 10px;
  padding-right: 5%;
}

.menu_text:hover {
  text-decoration: underline;
}

.menu_button {
  margin-bottom: 0.2vh;
  border-radius: 10px;
  background-color: #0353b2;
  width: 100%;
}

.menu_text {
  text-decoration: none;
  color: #f3dfdf;
}

.menu_container {
  /*padding-top: 50%;*/
  /*padding-left: 5%;*/
}
</style>