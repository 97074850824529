<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Мои проекты</h1>
  <div id="wrapper">
    <MenuComponent/>
    <div style="width: 100%;">
      <table class="table" style="width: 100%">
        <thead>
        <th scope="col">ID</th>
        <th scope="col">Название</th>
        <th scope="col">Создан</th>
        <th scope="col"></th>
        </thead>
        <tbody>
        <tr v-for="project in projects" :key="project['name']" style="width: 100%;" :class="check_if_unread(project['read_by_client'])">
          <td> {{ project["_id"] }}</td>
          <td> {{ project["name"] }}</td>
          <td> {{ new Date(project["created_at"]).toLocaleString().replace(',',' ') }} </td>
          <td>
            <a :href="'/admin/constructor?order=' + project['_id']"
               style="color: whitesmoke; padding: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
              Открыть макет
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/admin/ManagerPages/HeaderComponent";
import MenuComponent from "@/admin/ManagerPages/MenuComponent";
import FooterComponent from "@/admin/ManagerPages/FooterComponent";
import axios from "axios";

export default {
  name: "ProjectsPage",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      projects: []
    }
  },

  methods: {
    get_projects() {
      axios.post("/api/admin/get_projects", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => {
        this.projects = response.data
      })
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "table-active" : "";
    }
  },

  mounted() {
    this.get_projects()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.table>tbody>tr.table-active>td {
  --bs-table-bg-state: #FFDB93;
}
</style>