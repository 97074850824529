<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div style="width: 100%">
      <h1 class="display-1"
          style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">Личный кабинет заказчика</h1>
      <div class="form-check-inline" style="width: 30vw">
        <div class="form-group">
          <label><b>Наименование компании</b></label>
          <input class="form-control" type="text" v-model="this.user['name']" readonly/>
        </div>

        <div class="form-group">
          <label><b>ИНН</b></label>
          <input class="form-control" type="text" v-model="this.user['TIN']" readonly/>
        </div>

        <div class="form-group">
          <label><b>Почта</b></label>
          <input class="form-control" type="text" v-model="this.user['email']" readonly/>
        </div>

        <div class="form-group">
          <label><b>Контактное лицо</b></label>
          <input class="form-control" type="text"
                 v-model="this.user['contact_person']" readonly/>
        </div>

        <div class="form-group">
          <label><b>Должность</b></label>
          <input class="form-control" type="text"
                 v-model="this.user['position']" readonly/>
        </div>

        <div class="form-group">
          <label><b>Телефон</b></label>
          <input class="form-control" type="text" v-model="this.user['phone']" readonly/>
        </div>

        <div>
          <a href="/customer/password_edit" class="btn btn-primary mt-3 me-3" style="background-color: #0353b2">
            Поменять пароль
          </a>
          <a href="/customer/profile_edit" class="btn btn-primary mt-3" style="background-color: #0353b2">
            Изменить контактное лицо
          </a>
        </div>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/customer/HeaderComponent";
import MenuComponent from "@/customer/MenuComponent";
import FooterComponent from "@/customer/FooterComponent";
import axios from "axios";

export default {
  name: "ProfilePage",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      user: {}
    }
  },

  methods: {
    get_user() {
      axios.post("/api/customer/profile", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => this.user = response.data)
    }
  },

  mounted() {
    this.get_user()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}
</style>
