<template>
  <div id="menu">
    <ul class="list-group list-group-flush">
      <li class="menu_button list-group-item">
        <a href="/company/orders" class="menu_text">Мои заказы</a></li>
      <li class="menu_button list-group-item">
        <a href="/company/offer" class="menu_text">Оферта</a></li>
      <li class="menu_button list-group-item">
        <a href="/company/instruction_text" class="menu_text">Инструкция (текстовая)</a></li>
      <li class="menu_button list-group-item">
        <a href="/company/instruction_video" class="menu_text">Видео-инструкция</a></li>
      <li class="menu_button list-group-item">
        <a href="/company/profile" class="menu_text">Мой профиль</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComponent"
}
</script>

<style scoped>
#menu {
  font-family: Ubuntu;
  display: table-cell;
  float: left;
  width: 20%;
  border-radius: 10px;
  padding-right: 5%;
}

.menu_text:hover {
  text-decoration: underline;
}

.menu_button {
  margin-bottom: 0.2vh;
  border-radius: 10px;
  background-color: #0353b2;
}

.menu_text {
  text-decoration: none;
  color: #f3dfdf;
}
</style>
