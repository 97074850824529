<template>
  <HeaderComponent/>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div class="form-check-inline">
      <form>
        <div v-for="index in Object.keys(translates).reverse()" v-bind:key="index" style="width: 100%;">
          <div class="input-group" v-if="index!=='_id'">
            <input type="text" class="form-control" v-model=translates[index][0]>
            <input type="text" class="form-control" v-model="translates[index][1]">
            <button class="btn btn-danger" @click.prevent="delete translates[index]" style="margin: 5px">
              Удалить
            </button>
          </div>
        </div>
        <button class="btn btn-success" @click.prevent="addRow" style="margin: 5px">
          Добавить
        </button>

        <button class="btn btn-success" @click="updateTranslates" style="margin: 5px">
          Обновить переводы
        </button>
      </form>

    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import axios from "axios";

export default {
  name: "TranslatesSettings",
  data() {
    return {
      translates: {}
    };
  },
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},
  methods: {
    addRow() {
      this.translates[Object.keys(this.translates).length] = ['', '']
    },

    updateTranslates() {
      let new_translates = {}
      for (let i in this.translates) {
        new_translates[this.translates[i][0]] = this.translates[i][1]
      }
      delete new_translates["_id"]
      axios.post("/api/admin/update_translates", {
            "token": String(localStorage.getItem("access_token")),
            "translates": new_translates
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      )
    },

    loadTranslates() {
      axios.post("/api/admin/get_translates", {}, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => {
        for (let key in response.data) {
          this.translates[key] = [key, response.data[key]]
        }
      })
    }
  }
  ,
  mounted() {
    this.loadTranslates()
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 70vh;
}
</style>
