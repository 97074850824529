<template>
  <HeaderComponent/>
  <a href="#" id="back_button" v-if="role" style="position: absolute; margin-left: 4vw; font-size: 20px; color: black" @click="role=''">
    Назад
  </a>
  <h1 class="display-1" v-if="!role"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Изменение текстовой инструкции </h1>
  <h1 class="display-1" v-if="role==='company'"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Изменение текстовой инструкции поставщика</h1>
  <h1 class="display-1" v-if="role==='customer'"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Изменение текстовой инструкции заказчика</h1>

  <div id="wrapper">
    <AdminMenuComponent/>
    <div class="form-check-inline" v-if="!role">
      <div class="btn btn-primary mb-1" @click="role='company'" style="background-color: #0353b2;">
        Инструкция для поставщиков
      </div>
      <br>
      <div class="btn btn-primary" @click="role='customer'" style="background-color: #0353b2;">
        Инструкция для заказчиков
      </div>
    </div>
    <div style="display: block;">
      <textarea v-if="role==='company'" v-model="company_text_instruction" class="form-control"
                style="height: 16rem; width: 60vw; margin: 10px;"></textarea>
      <textarea v-if="role==='customer'" v-model="customer_text_instruction" class="form-control"
                style="height: 16rem; width: 60vw; margin: 10px;"></textarea>
    </div>
    <br>
    <div style="display: block;">
      <button class="btn btn-success" v-if="role" @click="updateInstructions"
              style="margin-top: 10px; background-color: #0353b2">
        Обновить инструкцию
      </button>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import axios from "axios";

export default {
  name: "VideoInstruction",
  data() {
    return {
      customer_video_instruction: '',
      company_video_instruction: '',

      customer_text_instruction: '',
      company_text_instruction: '',

      role: ''
    };
  },
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},
  methods: {
    updateInstructions() {
      axios.post("/api/admin/update_instructions", {
            "token": localStorage.getItem("access_token"),

            "customer_video_instruction": this.customer_video_instruction,
            "company_video_instruction": this.company_video_instruction,
            "customer_text_instruction": this.customer_text_instruction,
            "company_text_instruction": this.company_text_instruction,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then(() => {
        alert("Инструкции обновлены!");
        window.location.reload();
      })
    },
    loadInstructions() {
      axios.post("/api/admin/get_instructions", {
            "token": localStorage.getItem("access_token")
          },
          {
            headers: {
              "Content-Type": "application/json",
            }
          }).then((response) => {
        this.customer_video_instruction = response.data["customer_video_instruction"];
        this.company_video_instruction = response.data["company_video_instruction"];

        this.customer_text_instruction = response.data["customer_text_instruction"];
        this.company_text_instruction = response.data["company_text_instruction"];
      })
    }
  },
  mounted() {
    this.loadInstructions()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 85vh;
}
</style>
