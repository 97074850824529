<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div style="width: 100%;">
      <h1 class="display-1"
          style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">Видео-инструкция</h1>
      <div class="mt-3" style="text-align: center">
        <iframe :width=1200 :height=600 :src=link
                title="RuTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/company/HeaderComponent";
import MenuComponent from "@/company/MenuComponent";
import FooterComponent from "@/company/FooterComponent";
import axios from "axios";
// import axios from "axios";

export default {
  name: "CompanyInstructionVideo",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      link: ''
    }
  },

  methods: {
    get_instruction() {
      axios.get("/api/company/video_instruction").then((response) => {
        this.link = response.data
      })
    },
  },

  mounted() {
    this.get_instruction()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}
</style>
