<template>
  <div
      style="width: 20vw; height: 100vh; background-color: #002154; white-space: nowrap; display: inline-block; float: left">
  </div>
  <div class="d-flex align-items-center justify-content-center"
       style="width: 30vw; height: 100vh; padding-left: 0; float: left">
    <span class="">
      <h1 class="font-semibold text-4xl mb-3">Сброс пароля</h1>

      <div class="form-group">
        <label class="form-label" for="login"><span style="color: #002154; font-size: 14px"><b>Почта</b></span></label>
        <input
            id="email"
            style="width: 100%;"
            type="email"
            name="email"
            v-model="email"
            class="form-control"
            disabled
        />
      </div>

      <div class="form-group">
        <label class="form-label" for="psw"><span
            style="color: #002154; font-size: 14px;"><b>Новый пароль</b></span></label>
        <input id="psw"
               style="width: 100%;"
               type="password"
               placeholder="Введите новый пароль"
               class="form-control"
               name="psw"
               v-model="password">
                <p v-if="password_message" style="color: red">{{ password_message }}</p>
      </div>

      <div class="form-group mb-3">
        <label class="form-label" for="psw"><span
            style="color: #002154; font-size: 14px;"><b>Повторите пароль</b></span></label>
        <input id="psw"
               style="width: 100%;"
               type="password"
               placeholder="Повторите пароль"
               class="form-control"
               name="psw"
               v-model="password_repeat">
      </div>

      <button class="btn btn-primary" id="submit_button" @click.prevent="onSubmit">Подтвердить</button>
    </span>
  </div>

  <div class="d-flex align-items-center justify-content-center" style="margin-left: 50vw; width: 45vw; height: 100vh;">
    <iframe :src="link_to_hello_video" title="YouTube video player" style="width: 40vw; height: 45vh"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SetNewPassword",

  data() {
    return {
      link_to_hello_video: '',

      email: this.$route.query.email,

      password: '',
      password_repeat: '',

      password_message: ''
    }
  },

  methods: {
    onSubmit() {
      if (!this.password) {
        this.password_message = "Введите пароль"
        return
      } else this.password_message = ""
      if (this.password !== this.password_repeat) {
        this.password_message = "Пароли не совпадают!"
        return
      } else this.password_message = ""

      axios.post("/api/set_new_password", {
        "token": this.$route.query.token,
        "email": this.$route.query.email,
        "password": this.password
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.status === 200) {
          window.location.href = "/login"
        }
      })
    },

    loadSettings() {
      axios.get("/api/admin/settings", {
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.link_to_hello_video = response.data["link_to_hello_video"];
      })
    }
  },

  mounted() {
    this.loadSettings();

    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript)
  }
}
</script>

<style scoped>
body {
  font-family: Ubuntu;
}

#submit_button {
  width: 100%;
  background-color: #004376;
}

#submit_button:hover {
  background-color: #002154;
}
</style>
