<template>
  <header style="font-family: Ubuntu;">
    <img src="@/assets/logo.png" id="logo"/>
    <div style="margin-right: 3vw; float: right">
      <a id="burger" href="#" class="header_link" style="color: black"
         @click.prevent="menu_open = 1 - menu_open">
        Меню
      </a>
      <a href="/company/orders" class="header_link">Мои заказы</a>
      <a href="/company/instruction_text" class="header_link">Текстовая инструкция</a>
    </div>
    <div style="display: table; float: right; padding-right: 5vw">
      <div style="display: table-cell;">
        <a style="display: table-row">
          8 (812) 660 51 41
        </a>
        <a style="display: table-row">
          8 (911) 849 37 70
        </a>
        <a style="display: table-row">
          constract@lensiz.ru
        </a>
      </div>
      <div style="display: table-cell; padding-left: 1vw; text-align: center; vertical-align: middle">
        <button class="btn btn-danger" @click="open_call_modal(); show_call_modal = 1;" type="button"
                data-bs-toggle="modal" data-bs-target="#callModal"
                style="height: 2.5vw; background-color: #cb292f">
          Заказать звонок
        </button>
      </div>
    </div>
  </header>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="menu_open=false" v-if="menu_open"></div>
    <transition name="slide">
      <div v-if="menu_open" class="sidebar-panel">
        <ul class="sidebar-panel-nav">
          <li class="menu_button list-group-item">
            <a href="/company/orders" class="menu_text">Мои заказы</a></li>
          <li class="menu_button list-group-item">
            <a href="/company/offer" class="menu_text">Оферта</a></li>
          <li class="menu_button list-group-item">
            <a href="/company/instruction_text" class="menu_text">Инструкция (текстовая)</a></li>
          <li class="menu_button list-group-item">
            <a href="/company/instruction_video" class="menu_text">Видео-инструкция</a></li>
          <li class="menu_button list-group-item">
            <a href="/company/profile" class="menu_text">Мой профиль</a></li>
        </ul>
      </div>
    </transition>
  </div>
  <hr style="width: 92vw; margin-left: 4vw; margin-top: 0vw">
  <div class="modal fade" id="callModal" tabindex="-1" style="height: 19rem" aria-labelledby="CallLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 19rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 19rem; padding: 0; margin: 0">
        <div class="modal-header">
          <h5 class="modal-title text-danger" id="CallLabel">Заказать звонок</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <label for="exampleInputEmail1" class="form-label">Имя</label>
            <input type="text" class="form-control" placeholder="Введите ваше имя" v-model="user_name">
            <label for="exampleInputEmail1" class="form-label">Телефон</label>
            <input type="tel" class="form-control" placeholder="Введите ваш номер телефона" v-model="user_phone">
            <br>
            <button class="btn btn-success" style="text-align: center; width: 100%;" @click="recall()">
              Свяжитесь со мной
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CompanyHeaderComponent",

  data() {
    return {
      menu_open: false,
      show_call_modal: 0,

      user_name: "",
      user_phone: "",
    }
  },
  methods: {
    open_call_modal() {
      axios.post("/api/company/profile", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        this.user_name = response.data["contact_person"];
        this.user_phone = response.data["phone"]
      })
    },

    recall() {
      axios.post("/api/company/recall", {
            "token": localStorage.getItem("access_token"),
            "contact_person": this.user_name,
            "phone": this.user_phone,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      )
      alert("Пожалуйста, ожидайте, мы скоро Вам перезвоним!");
      window.location.reload();
    }
  },

  mounted() {
    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript)
  }
}
</script>

<style scoped>
header {
  font-family: Ubuntu;
  display: flex;
  font-size: 1vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.header_link {
  font-size: 1.2vw;
  padding-left: 2vw;
  color: black;
  text-decoration: none;
}

.header_link:hover {
  text-decoration: underline;
}

#logo {
  display: inline;
  vertical-align: middle;
  margin-right: 1vw;
  width: 15vw;
  padding-left: 5vw;
}
</style>
