<template>
  <HeaderComponent/>
  <a href="#" id="back_button" style="font-size: 20px; color: black; margin-left: 4vw;" @click="this.$router.go(-1)">
    Назад
  </a>
  <div id="wrapper">
    <MenuComponent/>
    <div class="form-check-inline" style="width: 30vw">
      <h3>Изменение пароля</h3>
      <div class="form-group">
        <label><b>Старый пароль</b></label>
        <input class="form-control" type="text" v-model="this.old_password"/>
      </div>
      <div class="form-group">
        <label><b>Новый пароль</b></label>
        <input class="form-control" type="text" v-model="this.new_password"/>
      </div>
      <div class="form-group">
        <label><b>Повторите пароль</b></label>
        <input class="form-control" type="text" v-model="this.repeat_password"/>
      </div>
      <div v-if="!this.password_match" style="color: red">
        Пароли не совпадают!
      </div>
      <button class="btn btn-success mt-3" @click="update_password">
        Подтвердить
      </button>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/customer/HeaderComponent";
import MenuComponent from "@/customer/MenuComponent";
import FooterComponent from "@/company/FooterComponent";
import axios from "axios";

export default {
  name: "CompanyPasswordEdit",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      user: {},

      old_password: '',
      new_password: '',
      repeat_password: '',

      password_match: true
    }
  },

  methods: {
    get_user() {
      axios.post("/api/company/profile", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => this.user = response.data)

    },

    update_password() {
      if (this.repeat_password === this.new_password) {
        axios.post("/api/company/update_password", {
              "token": localStorage.getItem("access_token"),
              "old_password": this.old_password,
              "new_password": this.new_password,
            }, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }
        ).then((response) => {
          if (response.status === 200) {
            alert("Данные успешно обновлены!")
          }
        }).catch((reason) => {
          if (reason.response.status === 400) {
            alert("Вы ввели неверный пароль!")
          }
        })
      } else {
        this.password_match = false;
      }
    }},

  mounted() {
    this.get_user()
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  margin-top: 2vh;
  min-height: 85vh;
}
</style>