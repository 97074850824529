<template>
  <header style="font-family: Ubuntu;">
    <img src="@/assets/logo.png" id="logo"/>
    <div style="margin-right: 3vw; float: right">
      <a id="burger" href="#" class="header_link" style="color: black"
         @click.prevent="menu_open = 1 - menu_open">
        Меню
      </a>
      <a href="/admin/manager_constructor" class="header_link">Конструктор</a>
      <a href="/admin/manager_projects" class="header_link">Мои проекты</a>
      <a href="/admin/manager_customers_page" class="header_link">Чат с заказчиками</a>
    </div>
    <div style="display: table; float: right; padding-right: 5vw">
      <div style="display: table-cell;">
        <a style="display: table-row">
          8 (812) 660 51 41
        </a>
        <a style="display: table-row">
          8 (911) 849 37 70
        </a>
        <a style="display: table-row">
          constract@lensiz.ru
        </a>
      </div>
      <div style="display: table-cell; padding-left: 1vw; text-align: center; vertical-align: middle">
        <button className="btn btn-danger" style="height: 2.5vw; background-color: #cb292f">
          Заказать звонок
        </button>
      </div>
    </div>
  </header>

  <hr style="width: 92vw; margin-left: 4vw; margin-top: 0">

  <div class="sidebar">
    <div class="sidebar-backdrop" @click="menu_open=false" v-if="menu_open"></div>
    <transition name="slide">
      <div v-if="menu_open" class="sidebar-panel">
        <ul class="sidebar-panel-nav">
          <li class="menu_button list-group-item" style="border-radius: 10px">
            <a href="/admin/manager_constructor" class="menu_text">Конструктор</a></li>
          <li class="menu_button list-group-item" style="border-radius: 10px">
            <a href="/admin/manager_projects" class="menu_text">Мои проекты</a></li>
          <li class="menu_button list-group-item">
            <a href="/admin/manager_customers" class="menu_text">Покупатели</a></li>
          <li class="menu_button list-group-item">
            <a href="/admin/manager_customers_page" class="menu_text">Чаты с покупателями</a></li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ManagerHeaderComponent",

  data() {
    return {
      menu_open: false
    }
  },

  mounted() {
    let jivoScript = document.createElement('script')
    jivoScript.setAttribute('src', '//code.jivo.ru/widget/VnHBsq8QLH')
    document.head.appendChild(jivoScript)
  }
}
</script>

<style scoped>
header {
  font-family: Ubuntu;
  display: flex;
  font-size: 1vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.header_link {
  font-size: 1.2vw;
  padding-left: 2vw;
  color: black;
  text-decoration: none;
}

.header_link:hover {
  text-decoration: underline;
}

#logo {
  display: inline;
  vertical-align: middle;
  margin-right: 1vw;
  width: 15vw;
  padding-left: 5vw;
}
</style>
