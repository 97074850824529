<template>
  <HeaderComponent/>
  <div id="wrapper">
    <MenuComponent/>
    <div style="width: 100%;">
      <h1 class="display-1"
          style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">Мои
        заказы</h1>

      <div class="form_radio_group">
        <div class="form_radio_group-item">
          <input id="radio-0" type="radio" value="-1" @click="orders_status=-1; update_page()"
                 :checked="orders_status===-1">
          <label for="radio-0">Все</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-6" type="radio" value="-1" @click="orders_status=-2; update_page()"
                 :checked="orders_status===-2">
          <label for="radio-6">Не отправлено</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-1" type="radio" value="0" @click="orders_status=0; update_page()"
                 :checked="orders_status===0">
          <label for="radio-1">На оценке</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-2" type="radio" value="1" @click="orders_status=1; update_page()"
                 :checked="orders_status===1">
          <label for="radio-2">Отказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-3" type="radio" value="2" @click="orders_status=2; update_page()"
                 :checked="orders_status===2">
          <label for="radio-3">Принятые заказы</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-4" type="radio" value="3" @click="orders_status=3; update_page()"
                 :checked="orders_status===3">
          <label for="radio-4">На пошиве</label>
        </div>
        <div class="form_radio_group-item">
          <input id="radio-5" type="radio" value="4" @click="orders_status=4; update_page()"
                 :checked="orders_status===4">
          <label for="radio-5">Завершенные заказы</label>
        </div>
      </div>

      <table class="table" style="width: 98%">
        <thead>
        <th scope="col">ID</th>
        <th scope="col">Название</th>
        <th scope="col">Дата последнего сообщения</th>
        <th v-if="orders_status===-1">Статус</th>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
        </thead>
        <tbody>
        <tr v-for="project in projects" :key="project['_id']"
            :class="check_if_unread(project['read_by_client'])">
          <td> {{ project["_id"] }}</td>
          <td> {{ project["name"] }}</td>
          <td> {{ new Date(project["last_message_at"]).toLocaleString().replace(',', ' ') }}</td>
          <td v-if="!Object.keys(project['messages']).length">Не отправлено</td>
          <td v-else-if="orders_status===-1">{{ choices_text[Number(project["status"])] }}</td>
          <td style="text-align: center;">
            <a :href="'/constructor?order=' + project['_id']"
               style="color: whitesmoke; display: block;padding: 4px 0 4px 0; border-radius: 5px; background-color: #0353b2; border: 0">
              Открыть в конструкторе
            </a></td>
          <td style="text-align: center;">
            <a :href="'/customer/order/' + project['_id']" v-if="!Object.keys(project['messages']).length"
               style="color: whitesmoke; display: block; width: 100%; padding: 4px 0 4px 0; border-radius: 5px; background-color: #0353b2; border: 0">
              Отправить на оценку
            </a>
            <a href="#" v-else-if="[1, 4].includes(project['status'])" @click="repeat_order(project['_id'])"
               style="color: whitesmoke; display: block; width: 100%; padding: 4px 0 4px 0; margin-bottom: 7px; border-radius: 5px; background-color: #0353b2; border: 0">
              Повторная оценка
            </a>
            <a :href="'/customer/order/' + project['_id']" v-else
               style="color: whitesmoke; display: block; width: 100%; padding: 4px 0 4px 0; border-radius: 5px; background-color: #0353b2; border: 0">
              Открыть чат
            </a>
          </td>
          <td v-if="!project['hidden']" style="text-align: center;">
            <a href="#" @click=" clicked_to_remove = project['_id']; show_clear_modal = 1;"
               data-bs-toggle="modal" data-bs-target="#clearModal"
               style="color: whitesmoke; display: block; padding: 4px 0 4px 0; border-radius: 5px; background-color: #cb292f; border: 0">
              Удалить проект
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal fade" id="clearModal" tabindex="-1" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" style="height: 10rem; padding: 0; margin: 0; width: 100%">
      <div class="modal-content" style="height: 10rem; padding: 0; margin: 0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="margin-left: auto; margin-right: 5px;margin-top: 5px;"></button>
        <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">Вы действительно хотите удалить
          заказ?</h5>
        <div style="text-align: center; padding-top: 20px">
          <button class="btn btn-danger" @click="remove_project(clicked_to_remove)" style="font-size: 17px">Да</button>
          <button class="btn btn-primary" style="margin-left: 20px; font-size: 17px" data-bs-dismiss="modal">Нет</button>
        </div>
      </div>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/customer/HeaderComponent";
import MenuComponent from "@/customer/MenuComponent";
import FooterComponent from "@/customer/FooterComponent";
import axios from "axios";
import {io} from "socket.io-client";


export default {
  name: "CustomerCreatedOrders",

  components: {HeaderComponent, MenuComponent, FooterComponent},

  data() {
    return {
      projects: [],
      show_clear_modal: 0,

      clicked_to_remove: -1,

      orders_status: -1,

      choices_text: {
        0: "На оценке",
        1: "Отказано",
        2: "Принято в работу",
        3: "На пошиве",
        4: "Завершен",
      }
    }
  },

  methods: {
    update_page() {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("orders_status", this.orders_status);
      window.location.search = searchParams.toString();
    },

    update_orders() {
      let find_options = {};
      if (new URLSearchParams(window.location.search).get("orders_status")) {
        this.orders_status = Number(new URLSearchParams(window.location.search).get("orders_status"));
      }
      if (![-1, -2].includes(this.orders_status)) {
        find_options["status"] = this.orders_status;
      }
      if (this.orders_status === 0) {
        find_options["messages"] = {"$ne": []};
      }
      if (this.orders_status === -2) {
        find_options["messages"] = [];
        find_options["status"] = 0;
      }

      axios.post("/api/customer/get_orders", {
            "token": localStorage.getItem("access_token"),
            "find_options": find_options
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        this.projects = response.data
      })
    },

    remove_project(order_id) {
      axios.post("/api/customer/remove_project", {
        "token": String(localStorage.getItem("access_token")),
        "order_id": Number(order_id),
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.socket.emit("/add_notification", {
          "token": String(localStorage.getItem("access_token")),
          "message": "Заказчик просит удалить заказ.",
          "type": "notification_info",
          "order_id": Number(order_id),
        });
        if (response.status === 200) {
          location.reload()
        }
      })
    },

    repeat_order(order_num) {
      axios.post("/api/customer/repeat_order", {
            "token": String(localStorage.getItem("access_token")),
            "order_id": order_num,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      )
      this.socket.emit("/add_notification", {
        "token": String(localStorage.getItem("access_token")),
        "message": "Заказчик просит произвести повторную оценку заказа.",
        "type": "notification_info",
        "order_id": order_num,
      });
      location.reload()
    },

    check_if_unread(bool_condition) {
      return bool_condition === false ? "table-active" : "";
    }
  },

  mounted() {
    const ws_path = "wss://конструктор.ленсиз.рф"  // ws://localhost:3000
    this.socket = io(ws_path, { // wss://конструктор.ленсиз.рф
      path: "/ws/socket.io",
      autoConnect: true,
    })

    this.update_orders();
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.table > tbody > tr.table-active > td {
  --bs-table-bg-state: #FFDB93;
}


.form_radio_group {
  display: inline-block;
  overflow: hidden;
}

.form_radio_group-item {
  display: inline-block;
  float: left;
}

.form_radio_group input[type=radio] {
  display: none;
}

.form_radio_group label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  user-select: none;
}

.form_radio_group .form_radio_group-item:first-child label {
  border-radius: 6px 0 0 6px;
}

.form_radio_group .form_radio_group-item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
  background: #ffe0a6;
}

/* Hover */
.form_radio_group label:hover {
  color: #666;
}
</style>
