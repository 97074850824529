<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Изменение видео-инструкции </h1>

  <div id="wrapper">
    <AdminMenuComponent/>
    <div class="form-check-inline">
      <div class="form-group">
        <label><b>Ссылка на инструкцию для заказчика</b></label>
        <input class="form-control" placeholder="Вставьте ссылку на видео" type="text"
               v-model="customer_video_instruction"/>
      </div>
      <div class="form-group">
        <label><b>Ссылка на инструкция для поставщика</b></label>
        <input class="form-control" placeholder="Вставьте ссылку на видео" type="text"
               v-model="company_video_instruction"/>
      </div>
      <button class="btn btn-success" @click="updateInstructions" style="margin-top: 10px; background-color: #0353b2">
        Обновить инструкции
      </button>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import axios from "axios";

export default {
  name: "VideoInstruction",
  data() {
    return {
      customer_video_instruction: '',
      company_video_instruction: '',

      customer_text_instruction: '',
      company_text_instruction: '',
    };
  },
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},
  methods: {
    updateInstructions() {
      axios.post("/api/admin/update_instructions", {
            "token": localStorage.getItem("access_token"),

            "customer_video_instruction": this.customer_video_instruction,
            "company_video_instruction": this.company_video_instruction,
            "customer_text_instruction": this.customer_text_instruction,
            "company_text_instruction": this.company_text_instruction,
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
      ).then(() => {
        alert("Инструкции обновлены!");
        window.location.reload();
      })
    },
    loadInstructions() {
      axios.post("/api/admin/get_instructions", {
            "token": localStorage.getItem("access_token")
          },
          {
            headers: {
              "Content-Type": "application/json",
            }
          }).then((response) => {
        this.customer_video_instruction = response.data["customer_video_instruction"];
        this.company_video_instruction = response.data["company_video_instruction"];

        this.customer_text_instruction = response.data["customer_text_instruction"];
        this.company_text_instruction = response.data["company_text_instruction"];
      })
    }
  },
  mounted() {
    this.loadInstructions()
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 85vh;
}
</style>
