<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Список менеджеров</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div style="width: 100%;">
      <a class="btn btn-primary" href="/admin/create_manager">
        Добавить менеджера
      </a>
      <table class="table" style="width: 100%">
        <thead>
        <th scope="col">Менеджер</th>
        <th scope="col">Описание</th>
        <th scope="col">Количество привязанных компаний</th>
        <th scope="col"></th>
        <th scope="col"></th>
        </thead>
        <tbody>
        <tr v-for="user in Object.keys(users)" v-bind:key="user" style="width: 100%;">
          <td> {{ users[user]["name"] }}</td>
          <td> {{ users[user]["about"] }}</td>
          <td> {{ users[user]["users_count"] }}</td>
          <td>
            <a :href="'/admin/manager_customers/' + users[user]['_id']" style="color: black">
              Открыть чаты
            </a>
          </td>
          <td>
            <a :href="'/admin/managers/' + users[user]['_id']" style="color: black">
              Редактировать
            </a>
          </td>
          <td v-if="users[user]['_id'] !== 0">
            <a href="#" @click="delete_manager(users[user]['_id'])" style="color: black">
              Удалить менеджера
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br>
  </div>
  <FooterComponent/>
</template>

<script>
import {defineComponent} from "vue";
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default defineComponent({
  name: 'ManagersPage',
  components: {HeaderComponent, FooterComponent, AdminMenuComponent},

  data() {
    return {
      users: [],
    }
  },

  methods: {
    get_managers: function () {
      axios.post("/api/admin/managers", {
            "token": localStorage.getItem("access_token"),
          }, {
            headers: {}
          }
      ).then((response) => {
        this.users = response.data;
      })
    },

    delete_manager: function (manager_id) {
      axios.post("/api/admin/delete_manager", {
            "token": localStorage.getItem("access_token"),
            "user_id": manager_id,
          }, {
            headers: {}
          }
      )
      window.location.reload();
    },
  },

  mounted() {
    this.get_managers()
  }
})
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  min-height: 85vh;
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}

.page_button {
  color: black;
  padding-right: 5px;
}
</style>