<template>
  <HeaderComponent/>
  <h1 class="display-1"
      style="text-align: center; font-size: 46px; font-family: Ubuntu; padding-top: 10px; color: #24509c">
    Управление профилем</h1>
  <div id="wrapper">
    <AdminMenuComponent/>
    <div class="form-check-inline" v-if="access_to_super_admin === false">
      <form>
        <div class="form-group">
          <label><b>Введите супер-пароль</b></label>
          <input class="form-control" placeholder="Пароль" type="text" v-model="password"/>
        </div>

        <button class="btn btn-success" @click.prevent="loginPanel" style="margin-top: 10px; background-color: #0353b2">
          Войти
        </button>
      </form>
    </div>
    <div class="form-check-inline" v-if="access_to_super_admin === true">
      <form>
        <div class="form-group">
          <label><b>Введите новый пароль для администратора</b></label>
          <input class="form-control" placeholder="Пароль" type="text" v-model="new_password"/>
        </div>

        <button class="btn btn-success" @click="updateData" style="margin-top: 10px; background-color: #0353b2">
          Подтвердить
        </button>
      </form>
    </div>
  </div>
  <FooterComponent/>
</template>

<script>
import AdminMenuComponent from "@/admin/AdminMenuComponent";
import HeaderComponent from "@/admin/HeaderComponent";
import FooterComponent from "@/admin/FooterComponent";
import axios from "axios";

export default {
  name: "CreateManager",
  components: {AdminMenuComponent, HeaderComponent, FooterComponent},

  data() {
    return {
      password: '',
      new_password: '',
      access_to_super_admin: false
    }
  },

  methods: {
    loginPanel() {
      axios.post("/api/admin/authorize_super_admin", {
            "token": String(this.password),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).then((response) => {
        this.access_to_super_admin = response.data
      }).catch((reason) => console.log(reason))
    },
    updateData() {
      axios.post("/api/admin/super_admin_panel", {
            "password": String(this.new_password),
          }, {
            headers: {
              'Accept':
                  'application/json',
              'Content-Type':
                  'application/json'
            }
          }
      ).catch((reason) => console.log(reason))
    }
  }
}
</script>

<style scoped>
#wrapper {
  font-family: Ubuntu;
  display: flex;
  margin-left: 4vw;
  width: 92vw;
  min-height: 85vh;
}
</style>